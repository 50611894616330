interface UnderlinedLinkProps {
  scrollTo: string,
  className?: string,
  children: React.ReactNode
}

const scrollToSection = (sectionId: string) => {
  const section = document.getElementById(sectionId);

  if (section) {
    window.scrollTo({
      behavior: 'smooth',
      top: section.offsetTop,
    });
  }
};

export default function UnderlinedLink({ scrollTo, children }: UnderlinedLinkProps) {
  return (
    <button
      className="w-full p-2 group transition-all duration-300 ease-in-out"
      onClick={() => scrollToSection(scrollTo)}
    >
      <span className='bg-left-bottom bg-gradient-to-r from-primary-100 to-primary-100 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out'>
        {children}
      </span>
    </button>
  )
}