interface ContainerProps {
  children: React.ReactNode,
  className?: string,
}

export const Container = ({ children, className }: ContainerProps) => {
  return (
    <div className={`w-[90%] md:w-4/5 m-auto ${className}`}>
      {children}
    </div >
  )
}