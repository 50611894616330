import { Section } from "../../components/atoms/section"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { images } from "./images";

export const Projects = () => {
  return (
    <Section id="projects">
      <div>
        <h2>Nuestros desarrollos</h2>
        <h3>Explorá nuestros proyectos de construcción, nos esforzamos por crear calidad.</h3>
      </div>

      <Carousel
        autoPlay={true}
        interval={3000}
        infiniteLoop
        emulateTouch
        swipeable
        stopOnHover
        showStatus={false}
        className="w-[90%] md:w-[75%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]"
        showThumbs={false}
      >
        {images.map((image, index) => {
          return (
            <div key={index} className="h-full flex justify-center items-center">
              <img src={image.src} alt={image.name} className="h-full object-cover" />
              {/* <p className="legend">{image.name}</p> */}
            </div>
          )
        })}
      </Carousel>
    </Section>
  )
}