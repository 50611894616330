import ServiceDataModel from "../../../../models/serviceDataModel"

interface GridItemProps {
  item: ServiceDataModel,
  className?: string,
}

export const GridItem = ({ item, className }: GridItemProps) => {
  return (
    <div className={`p-2 md:p-6 flex justify-evenly items-center h-full md:gap-4 bg-primary-50 rounded-md hover:bg-secondary-50 hover:drop-shadow-2xl group ${className}`}>
      <div className="w-1/5 border-2 border-secondary-500 bg-secondary-50 rounded-full group-hover:bg-secondary-200">
        <img src={item.iconPath} alt={item.title} className="p-3 m-auto lg:p-4 xl:p-6" />
      </div>
      <div className="grow flex flex-col gap-2">
        <h3 className="text-primary-900 font-bold text-xl">{item.title}</h3>
        <ul className="text-sm">
          {item.data?.map((row, index) => {
            return <li key={index} className="text-primary-700">{row}</li>
          })}
        </ul>
      </div>
    </div>
  )
}