import { Container } from "../../atoms/container"
import UnderlinedLink from "../../atoms/underlined-link"
import { Navbar } from "../../molecules/navbar"

interface HeaderProps {
  className?: string,
}

export const Header = ({ className }: HeaderProps) => {

  return (
    <header className={`sticky top-0 text-xl z-10 ${className}`}>
      <Container className={'flex justify-center items-center text-primary-100 relative'}>
        <div className="absolute left-0">
          <UnderlinedLink scrollTo="root" className="hidden md:block">
            <h1 className="w-0 hidden md:block">SICO</h1>
          </UnderlinedLink>
        </div>
        <Navbar />
      </Container>
    </header>
  )
}