import { Container } from "../components/atoms/container"
import { About } from "./about"
import { Contact } from "./contact"
import { Header } from "../components/organisms/header"
import { Projects } from "./projects"
import { Services } from "./services"
import { WhatsappLink } from "../components/molecules/whatsapp-link"
import { Toaster } from 'react-hot-toast'

export default function Home() {
  const classes = {
    gradient: 'bg-gradient-to-r from-black to-primary-900',
  }

  return (
    <>
      <Toaster />
      <Header className={classes.gradient} />
      <div className={`text-primary-100 ${classes.gradient}`}>
        <Container>
          <main>
            <About />
            <Services />
            <Projects />
            <Contact />
          </main>
        </Container>
        <WhatsappLink />
      </div>
    </>
  );
}
