import ServiceDataModel from '../../../../models/serviceDataModel';
import { GridItem } from '../../molecules/grid-item';

interface ServicesGridProps {
  data: ServiceDataModel[];
}

export const ServicesGrid = ({ data }: ServicesGridProps) => {
  return (
    <div className='grid lg:grid-cols-2 lg:grid-rows-2 w-full gap-1'>
      {data.map(item => (
        <GridItem key={item.title} item={item} />
      ))}
    </div>
  );
};