export const images = [
  {
    src: "./projects-images/antes-despues-01.jpeg",
    name: "antes-despues-01",
  },
  {
    src: "./projects-images/antes-despues-02.jpeg",
    name: "antes-despues-01",
  },
  // {
  //   src: "./projects-images/construccion-01.jpeg",
  //   name: "antes-despues-01",
  // },
  // {
  //   src: "./projects-images/construccion-02.jpeg",
  //   name: "antes-despues-01",
  // },
  // {
  //   src: "./projects-images/construccion-03.jpeg",
  //   name: "antes-despues-01",
  // },
  {
    src: "./projects-images/pileta-01.jpeg",
    name: "antes-despues-01",
  },
  {
    src: "./projects-images/pileta-02.jpeg",
    name: "antes-despues-01",
  },
]
