import GridDataModel from "../../../models/serviceDataModel"
import { Section } from "../../components/atoms/section"
import { ServicesGrid } from "../../components/organisms/services-grid"

const servicesData: GridDataModel[] = [
  {
    title: 'Remodelaciones',
    data: [
      'Renovación de interiores',
      'Renovación de exteriores',
      'Ampliaciones y extensiones',
    ],
    iconPath: './svg/tools.svg',
  },
  {
    title: 'Gestión integral',
    data: [
      'Planificación inicial',
      'Coordinación de profesionales',
      'Gestión de permisos y compras',
    ],
    iconPath: './svg/bulb.svg',
  },
  {
    title: 'Experiencia',
    data: [
      'Más de 20 años como profesionales',
      'Más de 300 clientes',
      'Más de 60 casas construidas',
    ],
    iconPath: './svg/briefcase.svg',
  },
  {
    title: 'Diseño',
    data: [
      'Asesoramiento',
      'Enfoque totalmente personalizable',
      'Accesibilidad universal',
    ],
    iconPath: './svg/design.svg',
  },
]

export const Services = () => {
  return (
    <Section className="w-full" id="services">
      <div className="text-center">
        <h2>Construimos</h2>
        <h3>¿Cómo podemos ayudarte en tu proyecto?</h3>
      </div>
      <ServicesGrid data={servicesData} />
    </Section>
  )
}