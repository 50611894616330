interface SectionProps {
  children: React.ReactNode,
  className?: string,
  id?: string,
}

export const Section = ({ children, className: classname, id }: SectionProps) => {
  return (
    <section id={id} className={`flex flex-col justify-center gap-12 items-center m-auto text-center ${classname}`} style={{ height: '100vh' }}>
      {children}
    </section>
  )
}