import UnderlinedLink from "../../components/atoms/underlined-link"

export const About = () => {
  return (
    <section className={'flex flex-col justify-evenly items-center'} style={{ height: 'calc(100vh - 44px)' }}>
      <div className="flex flex-col md:flex-row justify-evenly items-center text-center">
        <img src="./logo.svg" alt="SICO logo" className="w-2/4 md:w-1/4" />
        <div className="flex flex-col justify-center gap-4 md:gap-12">
          <div>
            <h1 className="font-bold md:m-2">Servicio Integral de la Construcción</h1>
            <h3 className="text-lg md:text-xl">
              Tu casa propia puede ser real.
              <br />
              En SICO reparamos, construimos y te asesoramos.
            </h3>
          </div>
          <div className="w-1/2 flex justify-center text-secondary-50 bg-secondary-500 hover:bg-secondary-600 font-bold rounded justify-self-end self-center hover:drop-shadow-2xl">
            <UnderlinedLink scrollTo="contact">
              Contactanos
            </UnderlinedLink>
          </div>
        </div>
      </div>
    </section>
  )
}
