import UnderlinedLink from "../../atoms/underlined-link"

export const Navbar = () => {
  return (
    <nav className="inline-flex text-sm sm:text-base">
      <UnderlinedLink scrollTo="root">Inicio</UnderlinedLink>
      <UnderlinedLink scrollTo="services">Servicios</UnderlinedLink>
      <UnderlinedLink scrollTo="projects">Desarrollos</UnderlinedLink>
      <UnderlinedLink scrollTo="contact">Contacto</UnderlinedLink>
    </nav>
  )
}