import { Section } from "../../components/atoms/section"
import { Footer } from "../../components/molecules/footer"
import { GridItem } from "../../components/molecules/grid-item"
import GridDataModel from "../../../models/serviceDataModel"
import toast from 'react-hot-toast'
import { PHONE_NUMBER } from "../../utils/constants"

export const Contact = () => {
  const handleCopyToClipboard = (item: GridDataModel) => {
    navigator.clipboard.writeText(item.title)
    toast.success(
      `El ${item.type} fue copiado al portapapeles.`,
      { position: "bottom-center" }
    )
  }

  const contactData: GridDataModel[] = [
    {
      title: PHONE_NUMBER,
      iconPath: './svg/phone.svg',
      onClick: handleCopyToClipboard,
      type: 'teléfono',
    },
    {
      title: 'sico@gmail.com',
      iconPath: './svg/mail.svg',
      onClick: handleCopyToClipboard,
      type: 'correo',
    },
    {
      title: 'España 2087, Planta Baja - Rosario, Santa Fe, Argentina',
      iconPath: './svg/location.svg',
    },
  ]

  return (
    <Section id="contact" className="relative">
      <div>
        <h2>Contacto</h2>
        <h3>Siempre dispuestos a asesorarte</h3>
      </div>
      <div className="w-full md:w-3/4 lg:w-1/2 flex flex-col gap-2">
        {contactData.map((item, index) => {
          return (
            <div
              key={index}
              onClick={item.onClick ? () => item.onClick!(item) : undefined}
            >
              <GridItem item={item} className="p-4" />
            </div>
          )
        })}
      </div>
      <Footer />
    </Section>
  )
}